<template>
  <div class="pa-2">
    <h1 class="headline mb-1 font-weight-bold">
      CPA会計学院様のご協賛開始についてのお知らせ
    </h1>
    <p class="body-1">
      2020年11月1日
    </p><img
      class="mt-3 mx-6 mb-5"
      src="@/assets/cpa/kakomon_banner_2023.jpg"
      eager="eager"
      contain="contain"
      max-width="auto"
      width="90%"
    >
    <h2 class="title mb-2 font-weight-black">
      ご協賛開始のお知らせ
    </h2>
    <p class="body-1 mb-1">
      会計士試験過去問コム（以下、本サイト）は、公認会計士受験生のために過去問を無料で解くことのできるWebアプリ、統計情報などを提供しております。
    </p>
    <p class="body-1 mb-1">
      本サイトは、主として個人の公認会計士が運営をしていますが、公認会計士受験生の試験勉強や受験情報の確保の充実に資することを目的としています。
    </p>
    <p class="body-1 mb-1">
      この度、本サイトは公認会計士試験の大手受験予備校である「CPA会計学院様」のご厚意により、協賛をいただくこととなりましたので、お知らせします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      令和2年第2回短答式試験の解説について
    </h2>
    <p class="body-1 mb-1">
      本サイトのWebアプリに2020年11月1日付けで追加した令和2年第2回の短答式試験の解説について、CPA会計学院様の公式解説を利用することを許諾いただきました。
    </p>
    <p class="body-1 mb-1">
      なお、令和2年第1回以前の短答式試験の解説は、個人の公認会計士が作成をしています。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      免責事項
    </h2>
    <p class="body-1 mb-1">
      令和2年第2回の解説は、本サイトの運営者がCPA会計学院様の利用許諾に基づき、利用をしています。無断転載は禁止です。
    </p>
    <p class="body-1 mb-1">
      また、CPA会計学院様へのお問い合わせについては、控えていただきますよう、お願い申し上げます。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      その他のご協賛のお願いについて
    </h2>
    <p class="body-1 mb-1">
      本サイトは公認会計士試験合格を目指す多くの受講生にご利用いただいています。
    </p>
    <p class="body-1 mb-1">
      つきましては、本サイトを運営するにあたり、本サイト上に協賛広告を掲載いただける団体・企業様を広く募集します。
    </p>
    <p class="body-1 mb-1">
      本サイトの趣旨にご理解・ご賛同いただく団体・企業様に、ぜひともご支援を賜りますよう、ご検討を頂けますと幸甚です。
    </p>
    <p class="body-1 mb-1">
      詳細につきましては、<a href="http://localhost:8080/contact">お問い合わせフォーム</a>からご連絡くださいませ。
    </p>
  </div>
</template>

<script>
  export default {
    name: "PrivacyPolicy"
  }
</script>

<style scoped>

</style>
